@font-face {
    font-family: "Uber Move";
    src: local("Uber Move"), url(./assets/fonts/Uber-Move.eot) format("eot");
    src: local("Uber Move"), url(./assets/fonts/Uber-Move.svg) format("svg");
    src: local("Uber Move"), url(./assets/fonts/Uber-Move.ttf) format("ttf");
    src: local("Uber Move"), url(./assets/fonts/Uber-Move.woff) format("woff");
    src: local("Uber Move"), url(./assets/fonts/Uber-Move.woff2) format("woff2");
}

@media (min-width: 768px) {
    body {
        max-height: 100vh;
        overflow: hidden;
    }
}

::-webkit-scrollbar {
    background-color: #fff;
    width: 14px;
}

/* background of the scrollbar except button or resizer */

::-webkit-scrollbar-track {
    background-color: #fff;
}

::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #97979b;
    border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */

::-webkit-scrollbar-button {
    display: none;
}

/* div box */
